import '../styles/index.scss';

const GO_BACK_CHECK_STRING = "ovh.org.uk";

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

(function () {
  window.addEventListener('message', handleMessage, false);
  function handleMessage(e) {
    if (e.data) {
      var split = e.data.split(':::');
      switch (split[0]) {
        case 'url':
          window.location.href = split[1];
          break;
      }
    }
  }

  /// check if we can go back
  if (
    (document.referrer != undefined &&
    document.referrer.includes(GO_BACK_CHECK_STRING))
  ) {
    document.getElementsByClassName("content")[0].className = "content include-back";
  }
})();

function requestFullScreen(element) {
  // Supports most browsers and their versions.
  var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullscreen;

  if (requestMethod) { // Native full screen.
    requestMethod.call(element);
  } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
    /*global ActiveXObject*/
    /*eslint no-undef: "error"*/
    var wscript = new ActiveXObject("WScript.Shell");
    if (wscript !== null) {
      wscript.SendKeys("{F11}");
    }
  }
}

if (document.addEventListener) {
  document.addEventListener('fullscreenchange', exitHandler, false);
  document.addEventListener('mozfullscreenchange', exitHandler, false);
  document.addEventListener('msFullscreenChange', exitHandler, false);
  document.addEventListener('webkitfullscreenchange', exitHandler, false);
}

function exitHandler() {
  // document.fullscreenElement will point to the element that
  // is in fullscreen mode if there is one. If there isn't one,
  // the value of the property is null.
  var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
  if (!fullscreenElement) {
    console.log('Leaving full-screen mode.');
    document.getElementsByTagName("iframe")[0].className = "";
  }
}


window.makeFullscreen = function () {
  document.getElementsByTagName("iframe")[0].className = "fullScreen";
  requestFullScreen(document.getElementsByTagName("iframe")[0]);
};

